<template>
  <div
    class="collapse menu-collapse"
    id="collapseLeagues"
    data-parent="#accordion"
  >
  
    <div class="menu-accordion p-2">
      <div class="row">
        <div class="col-6">
          <a @click="setLeague(-1)">
                <div class="item-wrapper text-white">All Leagues</div>
          </a>
        </div>
      

<!--      <div class="col-6" v-for="s in leagues"
        v-bind:key="getKey(s.competition_id)">
        <a @click="setLeague(s)">
          <div class="item-wrapper">
            <span class="text-white small-text" style="font-weight: 600;" v-text="s.country_name"></span><br />
            <span class="item-wrapper-span" v-text="s.competition"></span>
          </div>
        </a>
      </div>-->
        <div class="col-6" v-for="s in leagues" :key="getKey(s.competition_id)">
          <a @click="setLeague(s)">
            <div class="item-wrapper">
              <span class="text-white small-text" style="font-weight: 600;" v-text="s.country_name"></span><br />
              <span class="item-wrapper-span" v-text="s.competition"></span>
            </div>
          </a>
        </div>
    </div>
  </div>
  </div>
</template>

<script>
//import axios from "../../services/api";

export default {
  name: "leagues",
  mounted() {
    this.getMarket(1);
    this.$store.dispatch("getLeague")
    //this.getLeagues(this.spo);
  },
  computed: {
    sports: function () {
      return this.$store.state.sports;
    },

    leagues: function () {
      if (
        this.$store.state.leagues !== undefined &&
        this.$store.state.leagues !== null
      ) {
        // console.log(`LIG-->` + JSON.stringify(this.$store.state.leagues));

        return this.$store.state.leagues;
      }

      return this.$store.state.leagues;
    },

    markets: function () {
      return this.$store.state.markets;
    },
  },
  methods: {
    getLeagues: function (sport_id) {
      this.$store.dispatch("getLeague", sport_id);
    },
    getKey: function (index) {
      return Math.random()
        .toString(20)
        .replace("0.", "random-id-" + index + "-");
    },
    setSport: function (sport) {
      this.sport = sport;
      this.sport_name = sport.sport_name;
      this.getMarket(sport.sport_id);
      this.$store.dispatch("setSportID", sport.sport_id);
      this.$store.dispatch("setSportName", sport.sport_name);
      this.$store.dispatch("setMarketID", 0);
      this.$store.dispatch("setCompetitionID", 0);
      this.$store.dispatch("setCategoryID", 0);
    },

    setLeague: function (league) {
      document.getElementById("leagues-btn").click();

      if (league === -1) {
        this.competition_name = "Leagues";
        this.$store.dispatch("setCompetitionID", 0);
        return;
      }

      this.competition_name = league.country_name + " - " + league.competition;
      this.league = league;
      this.competition_name = league.competition;
      this.$store.dispatch("setCompetitionID", league.competition_id);
    },
    getMarket: function (sport_id) {
      this.$store.dispatch("getMarkets", sport_id);
    },
    setMarket: function (market) {
      this.market = market;
      this.market_name = market.market_name;
      this.$store.dispatch("setOutcomes", market.outcomes);
      this.$store.dispatch("setMarketID", market.market_id);
    },
    setHour: function (h) {
      this.selected_hour = h;
      //this.EventBus.$emit('filter:hour',h);
      this.$store.dispatch("setHour", h);
    },
    humanHourName: function (h) {
      if (h === -1) {
        return "All Time";
      }

      return h === "" || h === "" || h === 0 ? "Time" : h + " Hours";
    },
  },
  data: function () {
    return {
      sport: {},
      league: {},
      market: {},
      competition_name: "Top League",
      sport_name: "Sports",
      market_name: "Market",
      selected_hour: 0,
      hours: [-1, 1, 3, 6, 12, 24, 48],
    };
  },
};
</script>

<style scoped></style>
